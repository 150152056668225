/* Nanum Gothic */
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');

.featuredListMore{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    width: 100vw;
    height: 100vh;
    background-color: #1E71A6;
    font-family: 'Nanum Gothic', sans-serif;
    color: white;
}

.featuredListMore-left{
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 3rem;
}

.featuredListMore-left-img {
    width: 80%;
    border: 2px solid black;
}

.featuredListMore-right {
    width: 50%;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.featuredListMore-right > *{
    padding-top: 1.5rem;
}

.featuredListMore-right-price {
    font-size: 3rem;
}

.featuredListMore-right-address {
    font-size: 2rem;
    font-weight: bold;
}

.featuredListMore-bed-bath-sqft-type {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.featuredListMore-bed-bath-sqft-type, .featuredListMore-mls, .featuredListMore-desc{
    font-size: 1.25rem;
}

.featuredListMore-desc {
    padding-top: 3rem;
    width: 80%;
    line-height: 1.75;
}

.featuredList-delete {
    margin-top: 2rem;
    padding-bottom: 1rem;
    width: 50%;
    font-size: 2rem;
}

.featuredList-delete:hover {
    cursor: pointer;
    background-color: #4CA193;
}
