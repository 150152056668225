/* Nanum Gothic */
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');

.inquiryForm-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80vw;
    height: 90vh;
    border: 3px solid black;
    border-radius: 40px;
    font-family: 'Nanum Gothic', sans-serif;
    color: white;
    background-color: #4CA193;
}

.inquiryForm-heading{
    font-size: 3rem;
    padding-bottom: 1rem;
}

.inquiryForm-subheading{
    font-size: 1.5rem;
    padding-bottom: 1rem;
    width: 80%;
}

/* .inquiryForm-names {
    display: flex;
    justify-content: center;
    align-items: center;
} */

.inquiryForm-adds-email, .inquiryForm-names {
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    align-items: center;
}

.inquiryForm-names, .inquiryForm-adds-email {
    display: flex;
    justify-content: center;
    align-items: center;
}

.inquiryForm-desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inquiryForm-desc > input {
    height: 4rem;
    width: 30rem;
    margin-bottom: 2rem;
}

.inquiryForm-adds-email > *, .inquiryForm-names > *, .inquiryForm-desc > div {
    width: 50%;
    height: 3.5em;
    font-size: 1em;
    margin: 1em;
    border: 1px solid black;
    color: black;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inquiryForm-submit {
    background-color: grey;
    border: 2px solid black;
    height: 4rem;
    width: 12rem;
    font-size: 1.5rem;
}

.inquiryForm-submit:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}
