/************************************************* MenuOverlay  **************************************************/

.menuOverlay-main {
    background-color: #1E71A6;
    width: 100vw;
    height: 90vh;
    color: #ffffff;
    border: 5px solid black;

  }
  
  .menuOverlay-body {
    display: flex;
    height: 100%;
    width: 100%;
  }
  .menuOverlay-left {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
  }

  .menuOverlay-heading {
    width: 80%;
    height: 15%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .menuOverlay-heading-name {
    width: 40%;
    text-align: left;
    font-size: 3rem;
  }
  
  .menuOverlay-heading-phone {
    width: 40%;
    text-align: center;
  }

  .menuOverlay-left > section {
    width: 40%;
    height: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: center; */
  }

  .menuOverlay-label {
    padding-bottom: .5rem;
    font-size: 2rem;
  }
  
  .menuOverlay-ref {
    padding-top: .5rem;
    text-decoration: none;
    font-family: Barlow-Condensed;
    color: #ffffff;
    font-size: 1.25rem;
  }
  
  .menuOverlay-content {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .menuOverlay-content > img {
    width: 70%;
    height: auto;
    border: 1px solid #ffffff;
  }
  
  .content-label {
    font-size: 1.4rem;
    font-family: Barlow-Condensed;
    color: #ffffff;
  }

  .content-label {
    width: 70%;
    text-align: left;
  }

  .content-address {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .content-number, .content-email {
    padding-top: .5rem;
  }
  .content-email{
    margin-top: 1rem;
    text-decoration: none;
    color: white;
  }
  
  .content-icons {
    padding-top: .75rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 70%;
    height: 30%;
  }
  .admin {
    padding-top: 1rem;
    display: flex;
    text-decoration: none;
    color: white;
  }

  .admin:hover, .menuOverlay-ref:hover, .content-email:hover{
    color: #4CA193;
    cursor: pointer;
  }

  .content-icons > * {
    padding-right: 2rem;
    width: 40%;
  }

  .content-zillow-icon{
    width: 50%;
    /* border: 3px solid #ffffff; */
    border-radius: 10px;
    background-color: #4CA193;
  }

  .content-facebook-icon:hover, .content-instagram-icon:hover, .content-zillow-icon:hover{
    cursor: pointer;
  }