/* Nanum Gothic */
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');

.rent-main {
    width: 100vw;
    height: auto;
    background-color: #4CA193;
    font-family: 'Nanum Gothic', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rent-title{
    font-size: 3rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.add-homeForSale {
    margin-top: 3rem;
}
