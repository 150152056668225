/* Nanum Gothic */
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');

.homesForSale-main {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    align-items: center;
    background-color: #4CA193;
    font-family: 'Nanum Gothic', sans-serif;
}

.homesforSale-title{
    font-size: 3rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

}

/* Form */
.add-homeForSale {
    height: 110vh;
    width: 60%;
    background-color: white;
    border: 2px solid black;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
  
  .add-homeForSale-heading {
    font-size: 3rem;
    text-align: center;
  }
    
  .add-homeForSale-form {
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    align-content: center;
  }
  
  .add-homeForSale-row-col-inputs{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  
  }
  
  .add-homeForSale-price-baths-type, .add-homeForSale-sqft-beds-mls{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding: 1em; */
  }
  
  .add-homeForSale-price-baths-type > *, .add-homeForSale-sqft-beds-mls > *, .add-homeForSale-name{
    margin: .5em;
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
  }
  
  .add-homeForSale-name{
    justify-content: center;
  }
  
  .add-homeForSale-name > *, .add-homeForSale-price > *, .add-homeForSale-baths > *
  , .add-homeForSale-type > *, .add-homeForSale-sqft > *, .add-homeForSale-beds > *,
   .add-homeForSale-mls > *, .add-homeForSale-coverImage > div, .add-homeForSale-galleryImages > div{
    border: 2px solid black;
    padding: .5rem;
    font-size: 1.25rem;
  }
  
  .add-homeForSale-name-input, .add-homeForSale-price-input, .add-homeForSale-baths-input, .add-homeForSale-type-input, .add-homeForSale-sqft-input, .add-homeForSale-beds-input, .add-homeForSale-mls-input{
    margin-left: 1rem;
  }
  
  .add-homeForSale-image-cover{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .add-homeForSale-coverImage, .add-homeForSale-galleryImages{
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1rem;
  }
  
  .add-homeForSale-desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .add-homeForSale-desc > *{
    padding: .5rem;
    border: 2px solid black;
    margin-top: 1rem;
  }
  
  .add-homeForSale-desc > div {
    width: 20%;
  }
  
  .add-homeForSale-desc > input {
    width: 80%;
    height: 10vh;
  }
  
  .add-homeForSale-button{
    margin-top: 1.5rem;
    width: 100%;
  }
  
  .add-homeForSale-button > button {
    width: 30%;
    height: 4rem;
    font-size: 2rem;
    background-color: #1E71A6;
    color: white;
    border: 2px solid black;
  } 
  .add-homeForSale-button > button:hover {
    background-color: white;
    color: black;
    cursor: pointer;
  }
