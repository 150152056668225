.login_form_container {
    width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login_form_container form {
    display: flex;
    flex-direction: column;
  }
  
  .login_form_container label {
    margin-bottom: 5px;
  }
  
  .login_form_container input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login_form_container button {
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .login_form_container button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: red;
    text-align: center;
  }