/* Nanum Gothic */
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');

.aboutMore {
    padding-top: 3rem;
    display: flex;
    width: 100vw;
    height: 90vh;
    font-family: 'Nanum Gothic';
    color: white;
    background-color: #1E71A6;
}

.aboutMore-left{
    width: 45%;
}

.aboutMore-left-headshot {
    width: 60%;
    border: 2px solid black;
}

.aboutMore-right {
    width: 55%;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.aboutMore-right > *{
    padding-bottom: 1.5rem;
}

.aboutMore-right-name{
    font-weight: bold;
    font-size: 3rem;
}

.aboutMore-right-title, .aboutMore-right-dre, .aboutMore-right-phone,
.aboutMore-right-email-address, .aboutMore-right-desc {
    font-size: 1.25rem;
}

.aboutMore-right-title-dre, .aboutMore-right-emails{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.aboutMore-right-email-address {
    padding-left: 1rem;
}

.aboutMore-right-email-icon {
    width: 40px;
}

.aboutMore-right-desc {
    padding-top: 3rem;
    height: 10vh;
    width: 50vw;
}
