/* Nanum Gothic */
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');

.contactUs {
    background-color: #4CA193;
    padding-top: 10vh;
    height: 120vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-family: "Nanum Gothic", sans-serif;
    padding-bottom: 5rem;
}

.contactUs-heading {
    font-size: 5rem;
    padding-bottom: 3rem;
}


.contactUs-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-family: 'Nanum Gothic', sans-serif;
    font-size: 2em;
    text-align: right;
}

.contactUs-form-container {
    border: 5px solid black;
    border-radius: 30px;
    display: flex;
    width: 50%;
    height: 90%;
    justify-content: center;
    align-items: center;
}

.form-name-email {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.form-email, .form-name {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form-email > input, .form-name > input {
    width: 100%;
    height: 3.5em;
    font-size: .5em;
    border-radius: 20px;
    border: 2px solid black;
}

.form-name > div, .form-email > div {
    padding-right: 5rem;
    padding-bottom: 1rem;
}

.form-phone, .form-message{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 1rem;
}

.form-phone > input {
    width: 30%;
    height: 3em;
    font-size: .5em;
    border-radius: 20px;
    border: 2px solid black;
}

.form-message > input {
    width: 80%;
    height: 10em;
    font-size: .5em;
    border-radius: 20px;
    border: 2px solid black;
}

.form-phone > div, .form-message > div {
    padding-bottom: 1rem;
}

.form-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 2rem;
}

.form-submit > button {
    width: 30%;
    height: 5rem;
    font-size: 1.5em;
    border-radius: 20px;
    border: 2px solid black;
    background-color: black;
    color: white;
}

.form-submit > button:hover {
    background-color: white;
    color: black;
    cursor: pointer;
}