/* Nanum Gothic */
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');

.About1 {
    background-image: url("../../data/about_team_final.jpeg");
    background-size: cover;
    background-position: center 40%;
    height: 90vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 5em;
}

.About1 > div {
    font-family: 'Nanum Gothic', cursive;
    font-size: 7em;
    color: white;
    height: 30%;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.5);
}

.About2 {
    padding-top: 4rem;
    background-color: #4CA193;
    background-size: cover;
    height: 200vh;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 8em;
}

.about-card1{
    margin-top: 3rem;
    width: 35%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    font-family: 'Nanum Gothic', sans-serif;
    color: white;
    border: 3px solid black;
    background-color: #1E71A6;
    text-decoration: none;
    position: relative;
}

.about-card-left-bottom {
    font-size: 2.75rem;
}

.about-card-more{
    /* margin-top:auto; */
    padding: 1rem;
    font-size: 2rem;
    color: white;
    text-decoration: none;
    border: 1px solid #4CA193;
    background-color: #4CA193;
    position: absolute;
    bottom: 15px;
}

.about-card-more:hover{
    background-color: white;
    color: #4CA193;

}

.icon-back, .about-card-left-top, .about-card-num {
    font-size: 2rem;
}

img {
    width: 50%;
    border: 50%;
}

.Katt-icon {
    width: 70%;
}

