/* Nanum Gothic */
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');


.Home {
    display: flex;
    height: 85vh;
    width: 100%;
  }
  
  .home-main {
    background-image: url('../../data/location-winter.jpeg');
    background-size: cover;
    background-position: center -10vh;
    width: 100%;
    height: 100%;
  }

  .home-main-text{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 2em;
  }

  .home-main-title{
    display: flex;
    align-items: center;
    height: 35%;
    width: 80vw;
    font-family: "Nanum Gothic", sans-serif;
    font-size: 4rem;
    color: #1E71A6;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 30px;
  }

  .home-main-search {
    height: 15%;
    width: 50%;
    display: flex;
    flex-direction: row;
  }

  .home-main-input, .home-main-button {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .home-main-input {
    width: 70%;
    text-align: end;
    font-size: 1.5rem;
    padding-right: 1em;

  }
  .home-main-input::placeholder {
    text-align: center;
  }
  .home-main-input:focus::placeholder {
    color: transparent;
  }

  .home-main-button {
    width: 30%;
    display: flex;
    align-items: center;
    padding-left: .75em;
    color: white;
    background-color: black;
    font-size: 1.75rem;
  }
  .home-main-button:hover {
    background-color: #1E71A6;
    color: black;
    cursor: pointer;
  }

.search-icon {
    width: 1.5em;
    height: 1.5em;
    }

.home-contact{
  padding-top: 2rem;
  height: 80vh;
  background-color: #4CA193;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.home-contact-text{
  font-size: 2rem;
  margin-left: 5rem;
  margin-right: 5rem;
  line-height: 2;
}

.home-contact-details {
  font-size: 2rem;
  padding-bottom: 1.75em;
  line-height: 1.5;
}
.home-contact-details > :first-child {
  padding-bottom: .25em;
  font-size: 3rem;
}
.home-contact-details > :last-child {
  text-decoration: none;
  color: white;
}
.home-contact-details > :last-child:hover {
  color: #1E71A6;
}
