/* Nanum Gothic */
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');


.featuredlistings-main {
    padding-top: 3em;
    background-color: #4CA193;

    font-family: "Nanum Gothic", sans-serif;
    width: 100%;
  }
  .featuredlistings-main-heading {
    font-size: 3rem;
  }
  
  .featuredlistings-container {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
  }
  .featuredList-listing {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 3em;
    line-height: 2;
    text-align: left;
  }

  .featuredList-mainImage{
    width: 100%;
    height: 50vh;
    background-size: cover;
    background-position: center;
    border: 2px solid black;
  }
  .featuredList-mainImage > img{
    width: 100%;
    height: 100%;
  }

  .featuredList-value-price{
    font-size: 2.5rem;
  }

  .featuredList-address{
    font-size: 2rem;
    font-weight: bold;
  }

  .featuredList-brief-desc{
    display: flex;
    flex-direction: row;
    font-size: 1.5rem;
  }
  .featuredList-mls{
    font-size: 1rem;
  }
.featuredList-edit-link{
    font-size: 1.5rem;
    color: white;
    text-decoration: none;
    margin-top: 1em;
    padding: .5em;
    border: 1px solid #1E71A6;
    border-radius: 20px;
    background-color: #1E71A6;
  }
  .featuredList-edit-link:hover{
    color: black;
    background-color: white;
}

.editFeaturedListingForm{
  padding-top: 2rem;
  width: 100vw;
  display: flex;
  justify-content: center;
  background-color: #4CA193;
  font-family: "Nanum Gothic", sans-serif;
}

.add-featuredList {
  height: 110vh;
  width: 60%;
  background-color: white;
  border: 2px solid black;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.add-featuredList-heading {
  font-size: 3rem;
  text-align: center;
}
  
.add-featuredList-form {
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.add-featuredList-row-col-inputs{
  display: flex;
  flex-direction: row;
  justify-content: space-around;

}

.add-featuredList-price-baths-type, .add-featuredList-sqft-beds-mls{
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 1em; */
}

.add-featuredList-price-baths-type > *, .add-featuredList-sqft-beds-mls > *, .add-featuredList-name{
  margin: .5em;
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
}

.add-featuredList-name{
  justify-content: center;
}

.add-featuredList-name > *, .add-featuredList-price > *, .add-featuredList-baths > *
, .add-featuredList-type > *, .add-featuredList-sqft > *, .add-featuredList-beds > *,
 .add-featuredList-mls > *, .add-featuredList-coverImage > div, .add-featuredList-galleryImages > div{
  border: 2px solid black;
  padding: .5rem;
  font-size: 1.25rem;
}

.add-featuredList-name-input, .add-featuredList-price-input, .add-featuredList-baths-input, .add-featuredList-type-input, .add-featuredList-sqft-input, .add-featuredList-beds-input, .add-featuredList-mls-input{
  margin-left: 1rem;
}

.add-featuredList-image-cover{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-featuredList-coverImage, .add-featuredList-galleryImages{
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
}

.add-featuredList-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.add-featuredList-desc > *{
  padding: .5rem;
  border: 2px solid black;
  margin-top: 1rem;
}

.add-featuredList-desc > div {
  width: 20%;
}

.add-featuredList-desc > input {
  width: 80%;
  height: 10vh;
}

.add-featuredList-button{
  margin-top: 1.5rem;
  width: 100%;
}

.add-featuredList-button > button {
  width: 30%;
  height: 4rem;
  font-size: 2rem;
  background-color: #1E71A6;
  color: white;
  border: 2px solid black;
} 
.add-featuredList-button > button:hover {
  background-color: white;
  color: black;
  cursor: pointer;
}
